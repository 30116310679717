.main {
    padding-top: 65px;
    position: relative;
}

.flex1,
.flex2,
.flex3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.flex2 {
    margin: 30px 0;
}

.flex3 {
    flex-wrap: wrap;
    margin-bottom: 0;
}

.flex3 > * {
    margin-bottom: 40px;
}

.inputHeader {
    display: inline-block;
    margin-right: 30px;
}

.mainForm {
    padding: 40px;
    padding-bottom: 0;
    background: var(--white);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.btnContainer {
    display: inline-block;
    margin-right: 100px;
}

.section {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 20px 0;
}

.select {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.hr {
    padding-bottom: 2px;
}

.loader {
    width: 100%;
    height: 40vh;
    display: grid;
    place-items: center;
}

.pdf {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
}

.pdf > button {
    margin-right: 40px;
}

.checkbox {
    margin-top: 2px;
    margin-right: 6px;
}

.label {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.headerNotification {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--error);
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.headerNotificationContainer {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerNotificationText {
    color: var(--white);
}
