.arrow {
    position: absolute;
    right: 44px;
    top: 44px;
}

.section {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 20px 0;
} 

.section > * {
    margin-bottom: 40px;
}

.hr {
    border: none;
    height: 4px;
    background-color: var(--stroke);
    margin-bottom: 60px;
} 

.label {
    display: flex;
    align-items: center;
    width: calc(25% - 22.5px);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
}

.checkbox {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    cursor: pointer;
}