.colorItemContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-left: 1px solid #E7E7E8;
  position: relative;
}

.colorItemContainer1 {
  width: 100%;
  height: calc(100% - 1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarText {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: white;
}

.plusBlackIcon {
  height: 10px;
  width: 10px;
}

.noteTriangle {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #1B1B1B;
  position: absolute;
  bottom: 0;
  right: 0;
}

.triangleBorder {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #E7E7E8;
  position: absolute;
  bottom: 0;
  right: 0;
}
