.mainModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(71, 71, 71, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.modalContentContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 620px;
}

.addEmailContainer {
  margin-top: 15px;
}

.emailsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.inputAndButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.inputContainer {
  padding-top: 25px;
}

.bodyContainer {
  position: relative;
  margin-top: 25px;
}

.bodyTitle {
  margin-bottom: 10px;
  position: absolute;
  top: -5px;
  left: 5px;
  background-color: white;
  color: var(--secondary);
  padding: 0 5px;
  font-weight: 400;
  font-size: 12px;
}

.textArea {
  height: 250px;
  width: 100%;
  border-color: var(--stroke);
  padding: 12px;
  resize: none;
}

.textArea:focus {
  border-color: var(--stroke);
  outline: none;
}

.buttonRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
}

.headerContainer {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 20px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.dataContainer {
  padding: 16px;
}

.headerText {
  color: gray;
}

.closeButton {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.loader {
  width: 100%;
  height: 40vh;
  display: grid;
  place-items: center;
  position: absolute;
}
