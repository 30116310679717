.detailContainer {
  padding: 16px;
  flex-grow: 1;
  width: calc(100% - 200px);
  position: relative;
  z-index: 3;
}

.buttonText {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #0CADD3;
  border-radius: 100px;
  width: 200px;
  cursor: pointer;
}

.buttonContainer {
  width: 109px;
  height: 32px;
  border-radius: 32px;
  gap: 10px;
  margin: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quarterlyContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
}

.bigButtonContainer {
  width: 95px;
  height: 240px;
  border-radius: 6px;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowIcon {
  height: 12px;
  width: 6px;
}

.quarterContainer {
  width: 240px;
  height: 240px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #E7E7E8;
}

.monthName {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: black;
}

.datesQuarterContainer {
  margin-top: 8px;
}

.weekDayQuarterContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.weekDayQuarter {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 32px;
}

.weekDayQuarterGray {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: #A4A4A4;
  width: 32px;
}

.dateGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.dateItemContainer {
  width: 32px;
  height: 32px;
  padding: 3px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #E7E7E8;
  border-right: 1px solid #E7E7E8;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.dateGrid > .dateItemContainer:nth-child(7n) {
  border-right: none;
}

.dateGrid > .dateItemContainer:nth-last-child(-n+7) {
  border-bottom: none;
}

.dateGrid > .dateItemContainer:last-child {
  border-right: none;
}


.dateItemText {
  font-size: 10px;
  font-weight: 500;
}

.dateItemValue {
  font-size: 14px;
  font-weight: 600;
  color: white;
  z-index: 1000;
}

.loader {
  width: 100%;
  height: 8vh;
  display: grid;
  place-items: center;
  margin-top: 60px;
  position: absolute;
  z-index: 100;
}

.todayIndicator {
  height: 10px;
  width: 10px;
  background-color: #0CADD3;
  border: 1px solid white;
  border-radius: 5px;
  top: 2.5px;
  right: 3px;
  position: absolute;
}

.todayIndicator1 {
  height: 10px;
  width: 10px;
  background-color: #0CADD3;
  border: 1px solid white;
  border-radius: 5px;
  top: 1px;
  right: 3px;
  position: absolute;
}

.selectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.yearSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.yearlyContainer {
  margin-top: 16px;
}

.yearlyContainer::-webkit-scrollbar {
  height: 5px;
}

.arrowIconBig {
  height: 16px;
  width: 10px;
}

.yearText {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-right: 16px;
  margin-left: 16px;
}

.weekDayYear {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}

.weekDayYearGray {
  font-size: 10px;
  font-weight: 500;
  color: #A4A4A4;
}

.weekDayYearContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  min-width: 30px;
  margin-left: 50px;
  margin-right: 15px;
}

.dayYearlyContainer {
  height: 30px;
  width: 30px;
  border: 1px solid #ccc;
}

.dayYearlyContainerEmpty {
  height: 30px;
  width: 30px;
}

.monthNameText {
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 25px;
}

.monthItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  width: 44px;
}

.monthsContainer {
  display: flex;
  flex-direction: row;
}

.colContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.colContainer1 {
  display: flex;
  flex-direction: column;
  border: 1px solid #E7E7E8;
  border-radius: 6px;
  width: 100%;
}

.datesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dateContainer {
  height: 30px;
  width: 100%;
  border-top: 1px solid #E7E7E8;
  border-right: 1px solid #E7E7E8;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2px;
  position: relative;
  cursor: pointer;
}

.dateContainer1 {
  height: 29px;
  width: 100%;
  border-top: 1px solid #E7E7E8;
  border-right: 1px solid #E7E7E8;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  left: 0;
}

.dateNameText {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-top: 1px;
  z-index: 100;
}

.plusBlackIcon {
  height: 12px;
  width: 12px;
}

.plusContainerYear {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
}

.plusContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
