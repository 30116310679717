.modalContent {
  position: absolute;
  z-index: 50;
  background-color: #fefefe;
  width: 460px;
  max-height: 729px;
  border-radius: 8px;
  top: -28px;
  left: 52px;
  box-shadow: 0 0 20px 0 #0000001F;
  overflow-y: auto;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #E7E7E8;
  padding-left: 16px;
  padding-right: 16px;
}

.closeButton {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.contentContainer {
  position: relative;
  padding: 16px;
  z-index: -2;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #0CADD3;
  border-radius: 100px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
}

.buttonSwitchContainer {
  width: 226px;
  height: 44px;
  border-radius: 32px;
  gap: 10px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSwitchText {
  font-size: 16px;
  font-weight: 700;
}

.rangeContainer {
  margin-top: 16px;
  width: 100%;
  position: relative;
}

.typeDateText {
  font-size: 16px;
  font-weight: 500;
}

.eventTypeContainer {
  margin-top: 16px;
  width: 100%;
  position: relative;
  z-index: 10;
}

.eventTypeContainer1 {
  width: 206px;
  position: relative;
}

.eventTypeSelectValue {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
}

.titleText {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.titleTextContainer {
  margin-bottom: 8px;
}

.squareColorContainer {
  height: 16px;
  width: 16px;
  margin-left: 12px;
  border-radius: 2px;
}

.selectedEvent {
  margin-left: 5px;
}

.selectIcon {
  position: absolute;
  right: 16px;
}

.selectItemsContainer {
  position: absolute;
  width: 100%;
  height: 240px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #E7E7E8;
  box-shadow: 0 0 16px 0 #00000021;
  top: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 10000;
}

.calendar {
  z-index: 200000000000 !important;;
}

.selectItemsContainer1 {
  position: absolute;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #E7E7E8;
  box-shadow: 0 0 16px 0 #00000021;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100;
}

.selectItemsContainerEnds {
  position: absolute;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #E7E7E8;
  box-shadow: 0 0 16px 0 #00000021;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
}

.selectItemText {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.selectItemText1 {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #878787
}

.eventValuesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  position: relative;
  width: 100%;
}

.checkIcon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 16px;
}

.dateSelectsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  z-index: 1;
}

.dateSelectContainer {
  width: 189px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 200000000000 !important;;
}

.dateText {
  width: 82px;
  height: 19px;
  margin-left: 12px;
}

.calendarIcon {
  width: 20px;
  height: 22px;
  position: absolute;
  right: 12px;
}

.timeContainer {
  margin-top: 16px;
}

.allDayChecked {
  width: 112px;
  height: 60px;
  padding: 10px 12px 10px 12px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  margin-top: 16px;
}

.allDayCheckedText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-left: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.timeSelectContainer {
  width: 122px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.timeSelectContainerAdHoc {
  width: 106px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.timeText {
  font-size: 16px;
  font-weight: 400;
  color: #D0D0D0;
  padding-left: 12px;
  border: none;
}

.timeValueContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

.noteContainer {
  margin-top: 16px;
}

.notesInput {
  margin-top: 16px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
  outline: none;
}

.notesInput::placeholder {
  color: #D0D0D0;
}

.notesInputAdHoc {
  margin-top: 16px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
  outline: none;
}

.notesInputAdHoc::placeholder {
  color: #D0D0D0;
}

.buttonContainer {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background: #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
}

.saveButtonDisabled {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background: #D2D2D2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
}

.buttonText {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.divider {
  height: 1px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  background-color: #E7E7E8;
  margin-top: 16px;
}

.dividerSmall {
  height: 1px;
  width: 100%;
  background-color: #E7E7E8;
  margin-top: 16px;
}

.inputDate {
  height: 40px;
  border: 1px solid var(--stroke);
  border-radius: 10px;
  outline: none;
  text-indent: 12px;
  width: 100%;
  color: var(--black);
  background-image: url(../../../../assets/images/calendar.png);
  background-repeat: no-repeat;
  background-position: 94% center;
  padding-right: 12px;
  z-index: 20000000000;
}

.inputDate::placeholder {
  color: var(--gray);
}

.inputDateRed {
  height: 40px;
  border: 1px solid #FF5151;
  border-radius: 10px;
  outline: none;
  text-indent: 12px;
  width: 100%;
  color: var(--black);
  background-image: url(../../../../assets/images/calendar.png);
  background-repeat: no-repeat;
  background-position: 94% center;
  padding-right: 12px;
  z-index: 200;
}

.inputDateRed::placeholder {
  color: var(--gray);
}

.noteImg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adHocMainContainer {
  padding-top: 16px;
}

.adHocContainer {
  padding: 16px;
  border: 1px solid #E7E7E8;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.adHocName {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #0CADD3;
  padding-bottom: 16px;
}

.plusIcon {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.addDateText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #0CADD3;
  cursor: pointer;
}

.loader {
  position: absolute;
  left: 170px;
  top: 130px;
}

.loaderContainer {
  height: 100px;
}

.trashIcon {
  width: 20px;
  height: 21px;
}

.trashIconContainer {
  position: absolute;
  right: 30px;
}

.cancelButton {
  width: 206px;
  height: 40px;
  padding: 15px 40px 15px 40px;
  border-radius: 20px;
  border: 1px solid #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonsContainer1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px
}

.saveButton {
  width: 206px;
  height: 40px;
  padding: 15px 40px 15px 40px;
  border-radius: 20px;
  background-color: #0E7193;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.saveButtonDisabled1 {
  width: 206px;
  height: 40px;
  padding: 15px 40px 15px 40px;
  border-radius: 20px;
  background-color: #D2D2D2;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chooseDateText {
  font-size: 16px;
  font-weight: 400;
  color: #D0D0D0;
  position: absolute;
  left: 10px;
  z-index: 0;
}

.firsRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.firsRowContainer1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.allDayContainer {
  margin-top: 16px;
  z-index: -10;
}

.allDayText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #1D1D1D;
}

.atimeText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-right: 16px;
}

.selectTimeContainer {
  margin-top: 12px;
}

.timeSelect {
  border: none;
}

.timeSelectContainerRange {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 12px;
}

.titleTextTime {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #B4B4B4;
}

.openNoteContainer {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

.openNoteText {
  color: white;
  font-size: 20px;
  height: 21px;
}

.repeatContainer {
}

.repeatText {
  padding-top: 16px;
  padding-bottom: 16px;
}

.selectedEventPlaceholder {
  font-size: 16px;
  font-weight: 400;
  color: #D0D0D0;
  margin-left: 10px;
}

.repeatEveryContainer {
  padding-left: 16px;
  width: 100%;
}

.repeatEveryItem {
  margin-top: 8px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #E7E7E8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.disableButton {
  background: rgba(14, 113, 147, 0.4);
}

.repeatTextContainer {
  margin-left: 12px;
}

.repeatName {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #B4B4B4;
}

.inputText {
  border: none;
  max-width: 35px;
  text-align: center;
}

.inputText::-webkit-inner-spin-button,
.inputText::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputText {
  -moz-appearance: textfield;
}

.openNoteContainer, .openNoteText {
  user-select: none;
}

.daysContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.dayItem {
  width: 52px;
  height: 40px;
  border-radius: 10px;
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dayItemChecked {
  background-color: #0CADD3;
}

.dayText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.dayTextChecked {
  color: white;
}

.deleteContainer {
  width: 100%;
  height: 40px;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  background-color: #F0F0F0;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(192, 192, 192, 0.21);
  z-index: 1000;
}

.fullWidth {
  width: 100%;
  padding-top: 16px;
}
