.arrow {
    position: absolute;
    right: 44px;
    top: 44px;
}

.section {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 20px 0;
}

.section > * {
    margin-bottom: 40px;
}

.hr {
    border: none;
    height: 4px;
    background-color: var(--stroke);
} 

.warnMessage {
    position: relative;
    width: calc(33.3% - 20px);
    padding-left: 30px;    
}

.warnImg {
    position: absolute;
    left: 0;
}

.warnText {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--error);
}