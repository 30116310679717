.modalContent {
  position: absolute;
  background-color: #fefefe;
  width: 420px;
  min-height: 220px;
  max-height: 729px;
  border-radius: 8px;
  top: -28px;
  left: 52px;
  box-shadow: 0 0 20px 0 #0000001F;
  overflow-y: auto;
  z-index: 10000000000;
}

.headerContainer {
  height: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom: 1px solid #E7E7E8;
}

.squareColorContainer {
  height: 16px;
  width: 16px;
  margin-right: 12px;
  border-radius: 2px;
}

.squareColorContainerEmpty {
  height: 16px;
  width: 16px;
  margin-right: 12px;
  border-radius: 2px;
  border: 2px solid orange;
  background-color: #EB97234D;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px
}

.statusName {
  font-size: 16px;
  font-weight: 400;
}

.eventName {
  font-size: 20px;
  font-weight: 700;
}

.dataContainer {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.timeContainer {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.dateText {
  font-size: 16px;
  font-weight: 400;
}

.repeatContainer {
  padding: 16px;
}

.repeatText {
  font-size: 16px;
  font-weight: 400;
  color: #878787;
}

.repeatDataText {
  font-size: 16px;
  font-weight: 400;
  color: #2E2E30;
}

.noteText {
  color: #2E2E30;
  font-size: 16px;
  font-weight: 400;
}

.editIcon {
  height: 19px;
  width: 19px;
  margin-right: 11px;
  cursor: pointer;
}

.deleteIcon {
  height: 19px;
  width: 19px;
  margin-right: 11px;
  cursor: pointer;
}

.closeIcon {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.colContainer {
  display: flex;
  flex-direction: column;
}

.loader {
  position: absolute;
  left: 170px;
  top: 70px;
}

.noteContainerModal {
  padding: 8px;
  border-radius: 8px;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  margin: 16px 16px 10px;
}

.noteTitle {
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
}

.divider {
  color: #D9D9D9;
  margin-right: 5px;
  margin-left: 5px;
}

.applicantName {
  font-size: 16px;
  font-weight: 500;
}

.applicantNameContainer {
  padding-top: 16px;
  padding-left: 16px;
}

.clientNameContainer {
  padding-top: 8px;
  padding-left: 16px;
}

.clientName {
  font-size: 16px;
  font-weight: 500;
  color: #818181;
}

.noEvents {
  text-align: center;
  font-weight: bold;
}

.noEventsContainer {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: 200px;
}

.emptyView {
  height: 10px;
  border-bottom: 1px solid #E7E7E8;
  margin-bottom: 10px;
}
