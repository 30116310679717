.modalContainer {
  position: absolute;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  cursor: auto;
  z-index: 100;
  top: 110%;
  left: 0;
  transform: translate(0, 0);
}

.modalContainer1 {
  position: absolute;
  top: -100px;
  left: 270px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applyButton {
  padding: 8px 12px;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.applyButton:hover {
  background-color: var(--secondary);
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.optionButton {
  background-color: #eaeaea;
  color: #333333;
  padding: 5px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.optionButton:hover {
  background-color: #cccccc;
}

.optionButton:last-child {
  margin-bottom: 0;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 90px;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.itemName {
  margin-left: 10px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.1;
}

.filterItemContainer {
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 10px;
  padding-top: 10px;
}

.filterItemContainerFirst {
  display: flex;
  justify-content: flex-start;
  padding: 6px 16px 16px;
  border-bottom: 1px solid #E7E7E8;
  width: 100%;
}

.checkBox {
  cursor: pointer;
}

.mainContainer {
  width: calc(25% - 22.5px);
  position: relative;
}

.selectContainer {
  width: 100%;
  height: 40px;
  border: 1px solid var(--stroke);
  outline: none;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.selectContainerText {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #E7E7E8;
  cursor: pointer;
}

.applyButton {
  width: 90px;
  height: 32px;
  padding: 6px 24px 6px 24px;
  border-radius: 20px;
}

.filterIcon {
  width: 8px;
  height: 5px;
  margin-left: 10px;
}

.closeButton {
  width: 11px;
  height: 11px;
  margin-left: 8px;
}

.crossContainer {
  position: absolute;
  right: 16px;
}

.searchContainer {
  margin-top: 8px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #E7E7E8;
  width: 100%;
}

.inputContainer{
  width: 100%;
}

.clearButtonStyle {
  background-color: white;
  height: 30px;
  width: 30px;
  top: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trustFilterContainer{
  padding: 16px 8px;
  border-bottom: 1px solid #E7E7E8;
  width: 100%;
}

.buttonsContainerFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #0CADD3;
  border-radius: 100px;
  width: 100%;
  cursor: pointer;
}

.buttonSwitchContainer {
  width: 107px;
  height: 32px;
  border-radius: 32px;
  gap: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSwitchText {
  font-size: 16px;
  font-weight: 400;
}
