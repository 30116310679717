.modalContainer {
  position: absolute;
  top: 98px;
  right: -20px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 250px;
  cursor: auto;
  z-index: 100;
}

.modalContainer1 {
  position: absolute;
  top: -100px;
  left: 270px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.magnifyButton {
  height: 36.5px;
  width: 36px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  border: 1px solid #ccc;
}

.searchInputModal {
  padding: 8px;
  border: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: 20px;
  width: 195px;
}

.searchInputModal:focus {
  border-color: var(--stroke);
  outline: none;
}


.applyButton {
  padding: 8px 12px;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.applyButton:hover {
  background-color: var(--secondary);
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.optionButton {
  background-color: #eaeaea;
  color: #333333;
  padding: 5px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.optionButton:hover {
  background-color: #cccccc;
}

.optionButton:last-child {
  margin-bottom: 0;
}

.listContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itemName {
  margin-left: 10px;
  font-weight: 400;
  cursor: pointer;
}

.filterItemContainer {
  display: flex;
  justify-content: center;
}

.checkBox{
  cursor: pointer;
}
