.arrow {
  position: absolute;
  right: 44px;
  top: 44px;
}

.section {
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 60px 0 20px 0;
  overflow: visible;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex > * {
  margin-bottom: 40px;
}

.inputDate {
  height: 40px;
  border: 1px solid var(--stroke);
  border-radius: 10px;
  outline: none;
  text-indent: 12px;
  width: 100%;
  color: var(--black);
  background-image: url(../../assets/images/calendar.png);
  background-repeat: no-repeat;
  background-position: 94% center;
  padding-right: 12px;
}

.inputDate::placeholder {
  color: var(--gray);
}

.flexTab {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.tab {
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0;
  padding-bottom: 10px;
  margin: 0 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray);
  background: var(--white);
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
}

.tab:hover {
  color: var(--primary);
}

.hr {
  border: none;
  height: 4px;
  background-color: var(--stroke);
}

.footer {
  max-width: 1000px;
  display: grid;
  place-items: center;
  margin: 0 auto;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 0.1fr;
  row-gap: 20px;
  margin-bottom: 40px;
}

.footer2 {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 40px;
}

.footer2 {
  width: 870px;
}

.footerText {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  margin-right: 20px;
}

.footerTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--secondary);
  margin-bottom: 20px;
}

.dataTag {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary);
}

.grid {
  display: grid;
  place-items: center;
}

.paySettings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.required {
  border: 1px solid var(--error);
}

.mb {
  margin-bottom: 20px;
}

.dateContainer {
  position: relative;
  width: calc(25% - 22.5px);
  display: inline-block;
}

.datePlaceholder {
  position: absolute;
  top: -7px;
  left: 12px;
  padding: 0 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
  background: var(--white);
  z-index: 2;
}

.closeBtn {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.rateWrapper {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.rateSelectDivider {
  width: 20px;
}

.noteSection {
  margin-bottom: 60px;
  width: 100%;
  border: 1px solid rgba(13, 85, 130, 0.2);
  padding: 10px;
  border-radius: 4px;
  position: relative;
  min-height: 400px;
  max-height: 800px;
}

.noteTitle {
  margin-bottom: 10px;
  position: absolute;
  top: -8px;
  left: 5px;
  background-color: white;
  color: var(--secondary);
  padding: 0 5px;
  font-weight: 400;
  font-size: 12px;
}

.noteArea:focus {
  border-color: var(--stroke);
  outline: none;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.imageItem {
  position: relative;
  width: 100px;
  height: 90px;
  margin-right: 0;
}

.previewImage {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.deleteButton {
  position: absolute;
  top: 2px;
  right: 27px;
  background-color: #ee3333;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 5px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  position: relative;
}

.customInput {
  color: gray;
}

.editIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.toggleLabel {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

input[type="checkbox"]:checked + .slider {
  background-color: var(--secondary);
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(20px);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  position: relative;
  background-color: #fff;
  width: 90%;
  height: 90%;
  min-width: 400px;
  min-height: 400px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modalImage {
  width: 95%;
  height: 95%;
  object-fit: contain;
}

.modalCloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 1.5em;
  cursor: pointer;
}

.fileName {
  font-size: 14px;
}

.downloadButton {
  border-radius: 0;
  padding: 0;
  padding-bottom: 10px;
  margin: 0 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
}

