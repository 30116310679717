.mainModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(71, 71, 71, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.modalContentContainer {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 420px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 24px;
}

.closeButton {
  height: 13px;
  width: 13px;
}

.mainTextContainer {
  height: 67px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.orDeleteText {
  margin-top: 10px;
  cursor: pointer;
}

.buttonsContainer {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelButtonContainer {
  width: 186px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.deleteButtonContainer {
  width: 186px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EB3223;
  margin-left: 16px;
  cursor: pointer;
}

.deleteText {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.cancelText {
  font-size: 16px;
  font-weight: 600;
  color: #0E7193;
}

.deleteEvent {
  font-size: 20px;
  font-weight: 700;
}
