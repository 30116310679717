.modalContainer {
  position: absolute;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  cursor: auto;
  z-index: 100;
  top: 110%;
  left: 0;
  transform: translate(0, 0);
}

.modalContainer1 {
  position: absolute;
  top: -100px;
  left: 270px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applyButton {
  padding: 8px 12px;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.applyButton:hover {
  background-color: var(--secondary);
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.optionButton {
  background-color: #eaeaea;
  color: #333333;
  padding: 5px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.optionButton:hover {
  background-color: #cccccc;
}

.optionButton:last-child {
  margin-bottom: 0;
}

.listContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
}

.itemName {
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
}

.filterItemContainer {
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-top: 10px;
}

.filterItemContainerFirst {
  display: flex;
  justify-content: flex-start;
  padding: 6px 16px 16px;
  border-bottom: 1px solid #E7E7E8;
  width: 100%;
}

.checkBox {
  cursor: pointer;
}

.mainContainer {
  width: calc(25% - 22.5px);
  position: relative;
  margin-left: 60px;
}

.selectContainer {
  width: 100%;
  height: 40px;
  border: 1px solid var(--stroke);
  outline: none;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary);
  background-image: linear-gradient(45deg, transparent 50%, var(--secondary) 50%),
  linear-gradient(135deg, var(--secondary) 50%, transparent 50%);
  background-position: calc(100% - 19px) calc(1em + 2px),
  calc(100% - 15px) calc(1em + 2px),
  100% 0;
  background-size: 5px 5px,
  5px 5px,
  2.5em 2.5em;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.selectContainer:focus {
  background-image: linear-gradient(45deg, var(--secondary) 50%, transparent 50%),
  linear-gradient(135deg, transparent 50%, var(--secondary) 50%);
  background-position: calc(100% - 15px) 1em,
  calc(100% - 19px) 1em,
  100% 0;
  background-size: 5px 5px,
  5px 5px,
  2.5em 2.5em;
  background-repeat: no-repeat;
  outline: 0;
}

.selectContainerWithoutImage {
  width: 100%;
  height: 40px;
  border: 1px solid var(--stroke);
  outline: none;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.crossImageContainer {
  position: absolute;
  right: 15px;
}

.crossImage {
  height: 12px;
  width: 12px;
}

.selectContainerText {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
  color: black;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #E7E7E8;
  margin-top: 15px;
  cursor: pointer;
}

.applyButton {
  width: 90px;
  height: 32px;
  padding: 6px 24px 6px 24px;
  border-radius: 20px;
}

.checkedRadioButton {
  height: 20px;
  width: 20px;
}

.uncheckedRadioButton {
  height: 20px;
  width: 20px;
  border: 2px solid #A4A4A4;
  border-radius: 10px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  justify-content: space-between;
  width: 100%;
  padding-right: 12px;
}

.colorItem {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-left: 12px;
  margin-right: 8px;
}

.itemNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.daysMainContainer {
  border: 1px solid #0CADD3;
  height: 40px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 100px;
  margin-right: 12px;
  margin-left: 12px;
  box-sizing: border-box;
  width: calc(100% - 24px);
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.daysButtonContainerActive {
  width: 110px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0CADD3;
  cursor: pointer;
}

.daysButtonContainerInactive {
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.activeButton{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
}

.inactiveButton{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #2E2E30;
}
