@import './libs/normalize.css';
@import './libs/reset.css';

body {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  padding: 0 100px 50px 100px;
  overflow-x: hidden;
}

:root {
  --accent: #FBBC13;
  --primary: #00ADD2;
  --secondary: #0D5582;
  --black: #2E2E30;
  --white: #ffffff;
  --error: #F65164;
  --stroke: #E5E6E2;
  --gray: #AEAEB2;
  --green: #7DD141;
  --purple: #452779;
}

.react-calendar__tile abbr {
  display: none;
}

.react-datepicker__tab-loop {
  position: absolute;
}
