.container {
  width: 340px;
  position: relative;
}

.select {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 30px;
  border: 1px solid var(--stroke);
  outline: none;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, var(--black) 50%),
    linear-gradient(135deg, var(--black) 50%, transparent 50%);
  background-position:
    calc(100% - 19px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}
  
.select:focus {
  background-image:
    linear-gradient(45deg, var(--black) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--black) 50%);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 19px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  outline: 0;
}

.placeholder {
  position: absolute;
  top: -7px;
  left: 12px;
  padding: 0 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
  background: var(--white);
}

.x20 {
  width: 20%;
  margin-left: auto;
}

.x25 {
  width: calc(25% - 22.5px);
}

.x33 {
  width: calc(33.3% - 20px);
}

.x50 {
  width: calc(50% - 15px);
}

.x70 {
  width: 70%;
}

.x100 {
  width: 100%;
}

.duplicate,
.duplicate:focus {
  background: url(../../assets/images/duplicate.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  color: var(--primary);
  cursor: pointer;
}

.dupContainer {
  width: 60px;
}

.required {
  border: 1px solid var(--error);
}

.error {
  color: var(--error);
}

.warning {
  position: absolute;
  bottom: -16px;
  color: var(--error);
  left: 22px;
}

.dn {
  display: none;
}