.noteArea {
  height: auto;
  max-width: 75vw;
  border-color: var(--stroke);
  padding-top: 5px;
  resize: vertical;
}

.fullWidth {
  height: 200px;
  width: 100%;
  border-color: var(--stroke);
  padding-top: 5px;
  resize: vertical;
}

.ql-picker-label {
  text-align: left;
}

.editorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*height: 245px;*/
  width: 100%;
  height: auto;
}

.bulletPoint::before {
  content: "• ";
}

.bulletPoint {
  margin-bottom: 10px;
  margin-left: 5px;
}

.textContainer {
  padding-top: 50px;
}

.bulletPointEmpty {
  margin-left: 5px;
}

.bulletPointEmpty::before {
  content: "• ";
}

.ql-editor {
  overflow-x: auto;
  white-space: pre-wrap;
}

:global(.ck.ck-editor__main>.ck-editor__editable) {
  min-height: 200px;
  max-height: 400px;
  min-width: 50vw;
  max-width: 75vw;
  border-color: var(--stroke);
  padding-top: 5px;
  resize: both;
}

.qq {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;

}

.form11 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.filePicker {
  margin-top: 5px;
  margin-bottom: 0;
}

.filePickerEmpty {
  margin-top: 5px;
  margin-bottom: 0;
  height: 20px;
}

.downloadAllButton {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid transparent;
  margin-bottom: 2px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.checkBoxContainer {
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
}

.checkBoxLabel {
  cursor: pointer;
  font-weight: bold;
}

.checkBoxInput {
  margin-right: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
