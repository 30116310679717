.semicircle {
    width: 100%;
    max-height: 90px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.semicircleBottom {
    width: 100vw;
    max-height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
}