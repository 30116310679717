.main {
    padding-top: 30px;
}

.tableWrapper {
    background: var(--white);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 0 20px 40px 20px;
    max-height: 67vh;
}

.table {
    width: 100%;
    text-align: left;
}

.tbody {
    display: block;
    max-height: calc(67vh - 80px);
    overflow-y: auto;
}

.trHead {
    border-bottom: 1px solid var(--secondary);
}

.tr:nth-child(even) {
    background-color: var(--stroke);
}

.th,
.thFirst {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--secondary);
    padding: 40px 0 20px 0;
    cursor: pointer;
}

.td,
.tdFirst {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    padding: 16px 0;
}

.thFirst,
.tdFirst {
    padding-left: 20px;
}

.th1 {
    width: 20vw;
}

.th2 {
    width: 27vw;
}

.th3 {
    width: 9vw;
}

.th4 {
    width: 12vw;
}

.th5 {
    width: 23vw;
}

.td1 {
    width: calc(21vw - 10px);
}

.td2 {
    width: calc(26vw - 2px);
}

.td3 {
    width: calc(11vw - 8px);
}

.td4 {
    width: calc(12vw - 2px);
}

.td5 {
    width: calc(21vw - 2px);
}

.icon {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
}