.main {
    height: 72vh;
    position: relative;
}

.wrapper {
    width: 432px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    text-align: center;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: var(--black);
    margin-bottom: 50px;
}