.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  position: relative;
  overflow: auto

}

.modal h3 {
  margin-top: 0;
}

.modalContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  z-index: 1100;
}

.modal h3 {
  margin-top: 0;
}

.modal input {
  margin-bottom: 10px;
}

.modal button {
  margin-top: 20px;
}

.modal-content button {
  margin-right: 10px;
}

.mainContent {
  margin-top: 10px;
  width: 100%;
}


.emailsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.modalBorder {
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 5px;
  border: 1px solid #b4b4b4;
}

.modalWithoutBorder {
  padding: 5px 5px 5px 0;
  border-radius: 5px;
}

.removeIcon {
  cursor: pointer;
  color: #888;
  margin-left: 5px;
}

.removeIcon:hover {
  color: #f00;
}

.addEmailButton {
  margin-left: 10px;
}

.addEmailContainer {
  margin-top: 15px;
}

.textArea {
  height: 150px;
  width: 100%;
  border-color: var(--stroke);
  padding: 12px;
  resize: none;
}

.textArea:focus {
  border-color: var(--stroke);
  outline: none;
}

.attachmentsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 5px;
  min-width: 40%;
  overflow-y: auto;
  max-height: 150px;
}

.h4Attachment {
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

.attachmentsContainerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 150px;
  margin-left: 30px;
}

.attachmentsNewContainer {
  display: flex;
  flex-direction: row;
}

.attachmentsNewContainer1 {
  display: flex;
  flex-direction: column;
}

.attachmentItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.attachmentItem span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bodyTitle {
  margin-bottom: 10px;
  position: absolute;
  top: -5px;
  left: 5px;
  background-color: white;
  color: var(--secondary);
  padding: 0 5px;
  font-weight: 400;
  font-size: 12px;
}

.bodyContainer {
  position: relative;
  margin-top: 10px;
}

.replaceIcon {
  cursor: pointer;
  margin-right: 5px;
}

.uploadIcon {
  height: 20px;
  width: 20px;
}

.inputFile {
  display: none;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.checkboxItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.checkbox {
  margin-right: 10px;
}

.inputAndButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.loaderButton {
  padding: 0;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 5px;
}

.loaderButtonEmail {
  width: 100%;
  height: 19.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadLabel {
  display: inline-block;
  background-color: var(--secondary);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.uploadLabelGray {
  display: inline-block;
  background-color: rgba(13, 85, 130, 0.2);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.additionalFilesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  height: 25px;
  width: 300px;
}

.filePicker {
  margin: 0;
  padding: 0;
}

.headerName {
  width: 60%;
  margin: 0;
}

.customFileUpload {
  position: relative;
  margin-left: 20px;
  transform: translate3d(0,0,0);
  width: 40px;
}

.customFileUpload::after {
  content: attr(data-title);
  position: absolute;
  top: 45px; /* align vertically centered */
  left: 60px; /* align horizontally centered */
  transform: translate(-50%, -100%); /* shift it to the top */
  display: none;
  padding: 5px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  width: 80px;
}

.customFileUpload:hover::after {
  display: block;
  z-index: 30;
}
.hiddenFileInput {
  display: none;
}

.uploadIcon {
  height: 30px;
  width: 30px;
  margin-bottom: 0;
}

.tooltipWrapper {
  position: relative;
  bottom: 10px
}
