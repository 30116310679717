.tableContainer {
  border-collapse: collapse;
  margin-top: 8px;
  padding-right: 10px;
  padding-left: 10px;
}

.mainTableContainer {
  min-height: 499px;
  background-color: #F6F6F6;
  width: 200px;
  border-right: 1px solid #E7E7E8;
  border-left: 1px solid #E7E7E8;
  border-top: 1px solid #E7E7E8;
  flex-shrink: 0;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tableCell {
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #878787;
}

.tableCellRight {
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: right;
  flex-wrap: wrap;
  white-space: normal;
  padding-left: 5px;
  position: relative;
}

.contactItem {
  padding: 9px 8px;
  font-size: 14px;
  border-bottom: 1px solid #E7E7E8;
  border-top: 1px solid #E7E7E8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactItem1 {
  padding: 9px 8px;
  font-size: 14px;
  border-bottom: 1px solid #E7E7E8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.contactContainer {
  position: relative;
  cursor: pointer;
}

.bookingDetails {
  background-color: #FFF;
  position: absolute;
  right: -420px;
  top: -145px;
  width: 420px;
  height: 350px;
  padding: 0 8px 8px;
  gap: 4px;
  border: 1px solid #E7E7E8;
  overflow: auto;
  box-shadow: 4px 0 8px -2px rgba(0, 0, 0, 0.1),
  0 -4px 8px -2px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.bookingDetailsContacts {
  background-color: #FFF;
  position: absolute;
  right: -270px;
  top: 0;
  min-width: 270px;
  height: 104px;
  padding: 8px;
  gap: 4px;
  border: 1px solid #E7E7E8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 0 8px -2px rgba(0, 0, 0, 0.1),
  0 4px 8px -2px rgba(0, 0, 0, 0.1),
  0 -4px 8px -2px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.bookingDetailsNotes {
  background-color: #FFF;
  position: absolute;
  right: -242px;
  top: -105px;
  width: 242px;
  height: 269px;
  padding: 8px;
  gap: 4px;
  border: 1px solid #E7E7E8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  box-shadow: 4px 0 8px -2px rgba(0, 0, 0, 0.1),
  0 4px 8px -2px rgba(0, 0, 0, 0.1),
  0 -4px 8px -2px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.bookingDetailsPreferences {
  background-color: #FFF;
  position: absolute;
  right: -321px;
  top: -120px;
  width: 321px;
  height: 300px;
  padding: 8px;
  gap: 4px;
  border: 1px solid #E7E7E8;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 4px 0 8px -2px rgba(0, 0, 0, 0.1),
  0 4px 8px -2px rgba(0, 0, 0, 0.1),
  0 -4px 8px -2px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.notesValue {
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
}

.bookingDetailsContactItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.labelContacts {
  color: #878787;
  font-size: 10px;
  font-weight: 500;
}

.bookingDetailItemContacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.valueContacts {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #0CADD3;
}

.copyButton {
  padding: 10px 15px;
  border: 1px solid #0E7193;
  border-radius: 20px;
  background-color: white;
  color: #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  height: 24px;
  width: 69px;
  font-size: 14px;
}

.copyIcon {
  height: 14px;
  width: 10px;
  margin-right: 7px;
}

.itemArrowIcon {
  height: 14px;
  width: 6px;
}

.arrowIcon {
  font-size: 24px;
}

.headerPref {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
}

.idText {
  color: #B8B8B8;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.nameText {
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-left: 6px;
}

.itemSubtitle {
}

.itemGradesList {
  display: none;
  position: absolute;
  background-color: #2E2E30;;
  border: 1px solid #ddd;
  z-index: 100;
  padding: 4px;
  border-radius: 4px;
}

.tableCellRight:hover .itemGradesList {
  display: block;
}

.tooltipText {
  color: #fff;
  padding: 0;
  white-space: nowrap;
  margin: 0 5px 0 0;
}

.moreGrades {
  margin-left: 2px;
}

.topItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.bookingDetailItem {
  width: 48%;
}

.bookingItemContainer {
  background-color: #F6F6F6;
  margin: 16px 4px;
  border-radius: 8px;
  padding: 8px;
}

.label {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #878787;
  margin-top: 8px;
}

.value {
  font-size: 14px;
  font-weight: 500;
  color: #2E2E30;
  margin-top: 4px;
}

.valueId {
  font-size: 14px;
  font-weight: 500;
  color: #6A6A6A;
  margin-top: 4px;
}

.payRatesTable {
  margin-top: 8px;
}

.positionDivider {
  width: 100%;
  background-color: #E7E7E8;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.headerTable {
  border: 1px solid #E7E7E8;
  border-bottom: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.headerContent {
  border: 1px solid #E7E7E8;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.headerText {
  border-right: 1px solid #E7E7E8;
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #878787;
}

.contentTableText {
  border-right: 1px solid #E7E7E8;
  font-size: 14px;
  font-weight: 500;
  padding-left: 4px;
  color: #2E2E30;
  padding-top: 4px;
  padding-bottom: 4px;
}

.c1 {
  width: 170px;
}

.c2 {
  width: 65px;
}

.withoutBorder {
  border: none;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.withoutBorderRadius {
  border-radius: 0;
  border-bottom: none;
}

.noDataImg {
  height: 44px;
  width: 44px;
}

.noDataText {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: black;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.noUnderline {
  text-decoration: none;
}

.bookingIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}
