.flex {
    width: 1050px;
    display: flex;
    flex-direction: column;
}

.year {
    display: inline-block;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: var(--black);
}

.yearNav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    width: 36px;
}

.month {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    height: 30px !important;
    border: none !important;
}

.month >div:nth-child(1) {
    width: 72px;
    height: 36px;
}

.month >div:nth-child(1) button:nth-child(-n + 2),
.month >div:nth-child(1) button:nth-child(n + 4) {
    display: none;
}

.month >div:nth-child(1) button:nth-child(3) {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--black);
    text-align: left;
}

.month >div:nth-child(2) div:nth-child(1) div:nth-child(1) div:nth-child(1) > div:nth-child(1) {
    display: none !important;
}

.month >div:nth-child(2) div:nth-child(1) div:nth-child(1) div:nth-child(1) div:nth-child(2) {
    flex-wrap: nowrap !important;
    justify-content: start;
    align-items: start;
}

.month >div:nth-child(2) div:nth-child(1) div:nth-child(1) div:nth-child(1) div:nth-child(2) button:nth-child(1) {
    margin-left: 0 !important;
}

.monthTile,
.selectedTile,
.weekend {
    height: 30px !important;
    width: 30px !important;
    flex: 0 0 30px !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px !important;
    color: var(--black) !important;
    background: var(--white) !important;
    padding: 0 !important ;
    overflow: unset !important;
}

.weekend {
    background: #F5F5F5 !important;
}

.selectedTile {
    background: transparent !important;
    color: var(--black) !important;
    border: 2px solid var(--secondary) !important;
}

.selectedTile:hover {
    background: transparent !important;
}