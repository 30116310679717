.main {
  padding-top: 30px;
}

.tableWrapper {
  background: var(--white);
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowSecond {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.secondSelect {
  margin-left: calc(30px);
}

.selectorsWrapper {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  min-height: 60px;
  padding-bottom: 10px;
}

.rowThird {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  width: 200px;
}

.itemContainer:not(:last-child) {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #E7E7E8;
}

.itemHeader {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.itemArrow {
  margin-right: 8px;
  color: #333;
  position: relative;
}

.itemName {
  flex-grow: 1;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.starIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.starIconActive {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.itemAdd {
  color: #0CADD3;
  margin-left: 8px;
  font-size: 20px;
  cursor: pointer;
}

.itemSubtitle {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: 13px;
  position: relative;
  cursor: pointer;

}

.itemRole {
  color: black;
  font-size: 0.9em;
  height: 15px;
  margin-right: 5px;
}

.itemRole1 {
  color: #666;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  height: 15px;
}

.itemRole2 {
  max-width: 90px;
}

.itemMore {
  color: #666;
  font-size: 0.9em;
  height: 15px;
}

.itemsContainer {
  border: 1px solid #E7E7E8;
  width: 200px;
  background-color: #F6F6F6;
  position: relative;
}

.itemContainerBorder {
  border-top-left-radius: 8px;
  border-top: 0;
}

.lastItem {
  border-bottom-left-radius: 8px;
}

.itemArrowIcon {
  height: 12px;
  width: 6px;
  cursor: pointer;
}

.itemArrowIconDown {
  width: 11px;
  height: 7px;
  cursor: pointer;
}

.statusLegendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  width: 100%;
}

.statusLegendContainer1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  width: 100%;
}

.statusLegendItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusBox {
  width: 48px;
  height: 24px;
  margin-right: 5px;
  border-radius: 5px;
}

.noEvents {
  border: 1px solid #E7E7E8;
  background-color: white;
}

.available {
  background-color: green;
  margin-left: 36px;
}

.booked {
  background-color: orange;
  margin-left: 36px;
}

.notAvailable {
  background-color: red;
  margin-left: 36px;
}

.itemSubtitle {
  margin: 0 0 0 12px;
}

.datesMainContainer {
  margin-top: 24px;
  height: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 60px;
  z-index: 5;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 35px;
}

.dateSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 220px;
}

.dateArrowLeft {
  height: 16px;
  width: 10px;
  margin-right: 10px;
  margin-left: 3px;
  cursor: pointer;
}

.dateArrowRight {
  height: 16px;
  width: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.datesContainer {
  display: flex;
  flex-direction: column;
  min-width: 30px;
  height: 30px;
  align-items: center;
  justify-content: space-between;

}

.datesOverlap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
  margin-right: 8px;
}

.dateText {
  font-size: 16px;
  font-weight: 500;
}

.dateNameText {
  font-size: 10px;
  font-weight: 500;
}

.recruiterNameContainer {
  margin-top: 16px;
  margin-bottom: 8px;
}

.recruiterName {
  font-size: 16px;
  font-weight: 500;
}

.recruiterCountContainer {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recruiterCount {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.usersIcon {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: #ACACAC;
  margin-right: 6px;
  margin-left: 6px;
}

.showMoreContainer {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #E7E7E8;
  border-top: 0;
}

.showMoreText {
  color: #0CADD3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.loadMoreButtonContainer {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loadMoreButton {
  width: 104px;
  height: 36px;
  border-radius: 8px;
  background: #0CADD3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadMoreButtonText {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.loader {
  width: 84%;
  height: 8vh;
  display: grid;
  place-items: center;
  margin-top: 60px;
  position: absolute;
  z-index: 100;
}

.itemSpecialtiesList {
  display: none;
  position: absolute;
  background-color: #2E2E30;;
  border: 1px solid #ddd;
  z-index: 100;
  padding: 4px;
  border-radius: 4px;
}

.itemContainer1:hover .itemSpecialtiesList {
  display: block;
}

.tooltipText {
  color: #fff;
  padding: 0;
  white-space: nowrap;
  margin: 0 5px 0 0;
}

.itemContainer1 {
  display: flex;
  flex-direction: row;
}

.itemHeader1 {
  display: flex;
  flex-direction: row;
}

.noDataContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.noDataImg {
  height: 52px;
  width: 52px;
}

.noDataText {
  font-size: 20px;
  font-weight: 500;
  padding-top: 16px;
}

.openDetailContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #E7E7E8;
  border-right: 1px solid #E7E7E8;
  margin-bottom: -1px;
  align-items: stretch;
}

.detailLoader {
  position: absolute;
  bottom: -2px;
  left: -5px;
}

.colorsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #E7E7E8;
  height: 51px;
  flex-grow: 1;
}

.mainApplicantContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-right: 1px solid #E7E7E8;
  height: 50px;
}

.mainApplicantContainerBorder {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top: 1px solid #E7E7E8;
}

.colorItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-left: 1px solid #E7E7E8;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inputContainer {
  border-radius: 40px;
  margin-left: calc(30px);
}

.filterButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.filterIcon {
  width: 8px;
  height: 5px;
  margin-left: 10px;
}

.filterText {
  color: #0E7193;
  font-size: 16px;
  font-weight: 600;
}

.clearAllText {
  font-size: 16px;
  font-weight: 600;
  color: #878787;
  margin-right: 24px;
}

.openFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterCountContainer {
  width: 19px;
  height: 19px;
  border-radius: 10px;
  background-color: #0E7193;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.filterCount {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.placeholderStyle {
  left: 35px;
}

.switcherContainer {
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.periodSelect {
  margin-left: 30px;
  width: 100%;
}

.recruiterSelect {
  width: calc(25% - 22.5px);
}
