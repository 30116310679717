.inputDate {
    width: 150px;
    color: var(--gray); 
    background-image: url(../../../assets/images/calendar.png);
    background-repeat: no-repeat;
    background-position: 94% center;
    padding-right: 12px;
}

.scroll {
    max-height: 470px;
    overflow-y: auto;
    margin-bottom: 40px;
}

.hintOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
}

.hintWrapper {
    position: fixed;
    width: 30%;
    height: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 999;
    display: grid;
    place-items: center;
}

.hintText {
    font-size: 20px;
}