.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(46, 46, 48, 0.5);
    z-index: 3;
} 
  
.wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 40px;
    width: 610px;
    max-height: 100vh;
    overflow-y: auto;
    background: var(--white);
    z-index: 4;
}

.wrapper > input {
    margin-bottom: 30px;
}

.title {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: var(--black);
    margin-bottom: 50px;
}

.inputs > div {
    margin-bottom: 20px;
}

.submit {
    display: inline-block;
    margin-right: 30px;
}

.closeBtnWrapper {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 30px;
    right: 10px;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
  
.closeBtnArea {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.x100 {
    width: 84%;
}