.main {
  padding-top: 50px;
}

.tableWrapper {
  background: var(--white);
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 0 20px 40px 20px;
  max-height: 55vh;
}

.table {
  width: 100%;
  text-align: center;
}

.checkbox {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.checkboxFirst {
  padding-left: 5px;
}

.tbody {
  display: block;
  max-height: calc(55vh - 80px);
  overflow-y: auto;
}

.trHead {
  border-bottom: 1px solid var(--secondary);
}

.tr {
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
}

.tr:nth-child(even) {
  background-color: var(--stroke);
}

.tr:hover {
  background-color: rgba(0, 173, 210, 0.5);
}

.thFirst,
.tdFirst,
.thLast {
  text-align: left;
  white-space: nowrap;
}

.th,
.thFirst,
.thCompliance,
.thLast {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary);
  padding: 40px 5px 20px 0;
  cursor: pointer;
}

.th {
  white-space: nowrap
}

.thCompliance {
  width: 9vw;
  padding-right: 15px;
}

.thFirst {
  position: relative;
  width: 13vw;
}

.thSecond {
  position: relative;
  color: var(--secondary);
  font-weight: bold;
  width: 11vw;
}

.thFormId {
  width: 5vw;
  color: var(--secondary);
  font-weight: bold;
}

.tdFirst {
  padding-left: 5px;
  white-space: nowrap;
  flex-direction: row;
  display: flex;
  align-items:  center;
}

.td,
.tdLast {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  padding: 16px 0;
  position: relative;
}

.th5 {
  width: 17vw;
  padding-left: 5px;
}

.td1 {
  width: 12vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tdNew {
  width: 11vw;
  padding-left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td2 {
  width: 9vw;
}

.td3 {
  width: 11vw;
}

.td4 {
  width: 11vw;
}

.td4Date {
  width: 10vw;
}

.td4Draft {
  width: 10vw;
}

.td5 {
  width: 16vw;
}

.td6 {
  width: 19vw;
}

.tdLast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 14px;
}

.btnsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.flex {
  display: flex;
}

.create {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid transparent;
  border-radius: 30px;
  height: 40px;
  background: var(--secondary);
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
  width: 128px;
  margin-right: 30px;
}

.create:hover {
  background: var(--white);
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

.btnContainer {
  display: inline;
  margin-right: 20px;
}

.compliantContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  margin-right: 10px;
}

.compliant,
.notCompliant {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.compliant {
  color: var(--green);
}

.notCompliant {
  color: var(--error);
}

.icon {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 2px;
}

.filterIcon {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  height: 15px;
  width: 15px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  margin-top: 30px;
  padding-bottom: 30px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  margin: 0 5px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link_active a {
  color: #fff;
  background: var(--secondary);
}

.pagination__link_disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.flexColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modalPosition {

}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2.5vh;
  position: relative;
}

.lastContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-left: 15px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.editIcon {
  height: 15px;
  width: 15px;
  /*position: absolute;*/
  margin-left: 5px;
  /*top: 18px*/
}

.completedContainer{
  width: 85%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:window-inactive {
  opacity: 0;
}
